import React from 'react'
import { useRouteError } from 'react-router-dom'

interface errorType {
  statusText?: string
  message: string
}

function ErrorPage() {
  const error = useRouteError()
  const err = error as errorType

  console.log(err)

  return (
    <div id='error-page'>
      <h1>Oops!</h1>
      <p>Sorry, an unexpected error has occurred.</p>
      <p>
        <i>{err.statusText ?? err.message}</i>
      </p>
    </div>
  )
}

export default ErrorPage
