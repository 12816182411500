import React, { ReactNode, useEffect, useState } from 'react'

function DeferredComponent({ children }: { children: ReactNode }) {
  const [isDeferred, SetIsDeferred] = useState(false)

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      SetIsDeferred(true)
    }, 200)
    return () => clearTimeout(timeoutId)
  }, [])

  if (!isDeferred) {
    return null
  }

  return <>{children}</>
}

export default DeferredComponent
