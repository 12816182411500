import DeferredComponent from '@/components/common/DeferredComponent'
import Heading from '@/components/common/Heading'
import SearchBox from '@/components/common/SearchBox'
import { ErrorFallback } from '@/components/DomainManagement'
import EmailListSkeleton from '@/components/EmailListSkeleton'
import { useDomainUserList } from '@/module/domain/hook'
import React, { Fragment, lazy, Suspense } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import _ from 'lodash'

const DomainAdminEmailManagement = lazy(
  async () => await import('../components/DomainAdminEmailManagement'),
)

function DomainAdminEmailConfiguration() {
  const { setFilterText, filterAdminEmailList } = useDomainUserList()

  const debounceFilterText = _.debounce((value: string) => {
    setFilterText(value)
    filterAdminEmailList()
  }, 200)

  return (
    <Fragment>
      <Heading container='px-6'>관리자 이메일 설정</Heading>
      <SearchBox placeholder='이메일 검색' onChange={debounceFilterText} />
      <ErrorBoundary FallbackComponent={ErrorFallback} onError={(err) => alert(err.message)}>
        <Suspense
          fallback={
            <DeferredComponent>
              <EmailListSkeleton />
            </DeferredComponent>
          }
        >
          <DomainAdminEmailManagement />
        </Suspense>
      </ErrorBoundary>
    </Fragment>
  )
}

export default DomainAdminEmailConfiguration
