import React, { lazy, Suspense } from 'react'
import _ from 'lodash'
import * as Sentry from '@sentry/react'
import { ErrorBoundary } from 'react-error-boundary'

import DomainListSkeleton from '@/components/DomainListSkeleton'
import { ErrorFallback } from '@/components/DomainManagement'
import DeferredComponent from '@/components/common/DeferredComponent'
import { useDomainList } from '@/module/domain/hook'
import SearchBox from '@/components/common/SearchBox'
import Heading from '@/components/common/Heading'

const DomainManagement = lazy(async () => await import('../components/DomainManagement'))

function Domain() {
  const { setFilterText, filterDomainList } = useDomainList()

  const debounceFilterText = _.debounce((value: string) => {
    setFilterText(value)
    filterDomainList()
  }, 200)

  return (
    <div className='grow'>
      <Heading container='px-6'>도메인 목록</Heading>
      <SearchBox placeholder='도메인 검색' onChange={debounceFilterText} />
      <ErrorBoundary
        FallbackComponent={ErrorFallback}
        onError={(err) => Sentry.captureException(err)}
      >
        <Suspense
          fallback={
            <DeferredComponent>
              <DomainListSkeleton />
            </DeferredComponent>
          }
        >
          <DomainManagement />
        </Suspense>
      </ErrorBoundary>
    </div>
  )
}

export default Domain
