import React, { Fragment, ReactNode, useEffect, useState } from 'react'
import queryString from 'query-string'
import { useCookies } from 'react-cookie'
import { Navigate, useLocation } from 'react-router-dom'

import { useAuth } from '@/module/auth/hook'
import { User } from '@/module/auth/types'
import { initialUser } from '@/module/auth/context'
import { useDebug } from '@/module/home/hook'

function AuthRoute({ children, navigate }: { children: ReactNode; navigate?: boolean }) {
  const location = useLocation()
  const { redirectTo, loggedIn } = queryString.parse(location.search)

  const [cookies] = useCookies(['user'])
  const { handleSetUser } = useAuth()
  const { debug } = useDebug()

  useEffect(() => {
    const user: User = cookies.user

    if (cookies.user === undefined || !user.email.endsWith('flexcil.com')) {
      handleSetUser(initialUser)
      return
    }

    handleSetUser(user)
  }, [cookies])

  if (!debug) {
    if (navigate && !cookies.user) {
      return <Navigate to={`/?redirectTo=${location.pathname}&loggedIn=false`} replace />
    }

    const hasRedirectTo =
      redirectTo !== null && !Array.isArray(redirectTo) && redirectTo !== undefined

    const afterLoggedIn = loggedIn !== null && !Array.isArray(loggedIn) && loggedIn === 'true'
    if (hasRedirectTo && afterLoggedIn) {
      return <Navigate to={redirectTo} replace />
    }
  }

  return <Fragment>{children}</Fragment>
}

export default AuthRoute
