import React, { useEffect } from 'react'
import { Outlet, useLocation, useParams } from 'react-router-dom'
import { useOpened, useSelected } from './module/home/hook'
import { useDomainList, useDomainValidEmailList } from './module/domain/hook'
import SideMenu from './components/common/SideMenu'
import { ProductType } from './types'
import { initialDomain } from './module/home/context'

function App() {
  const { openSidemenu, setOpenSidemenu } = useOpened()
  const { setSelectedProductType, setSelectedDomain, setIsValidEmailType, setIsAdminList } =
    useSelected()
  const { setFilterText: setDomainFilter, setSelectedDomain: setDomainListSelected } =
    useDomainList()
  const { setFilterText: setEmailFilter } = useDomainValidEmailList()
  const { pathname } = useLocation()
  const { productId, domain } = useParams()

  useEffect(() => {
    const homepath = '/'

    setDomainFilter('')
    setEmailFilter('')
    setOpenSidemenu(pathname !== homepath)
    setIsValidEmailType(pathname.endsWith('/list'))
    setIsAdminList(pathname.endsWith('/admin-list'))
    setDomainListSelected(null)

    if (productId) {
      setSelectedProductType(productId as ProductType)
      if (!domain) {
        setSelectedDomain(initialDomain)
      }
    }
  }, [pathname])

  return (
    <div className='flex w-screen h-screen bg-base-100'>
      {openSidemenu && <SideMenu />}
      <Outlet />
    </div>
  )
}

export default App
