import React, { useState } from 'react'

type SearchBoxProps = {
  placeholder: string
  onChange: (e: string) => void
}

function SearchBox({ placeholder, onChange }: SearchBoxProps) {
  const [searchValue, setSearchValue] = useState<string>('')

  return (
    <div className='px-6 pb-2 form-control lg:p-4 lg:px-6'>
      <input
        type='search'
        placeholder={placeholder}
        value={searchValue}
        onChange={(e) => {
          setSearchValue(e.target.value)
          onChange(e.target.value)
        }}
        className='w-full text-sm input input-bordered input-sm'
      />
    </div>
  )
}

export default SearchBox
