import React, { Fragment, lazy, Suspense } from 'react'
import * as Sentry from '@sentry/react'
import { ErrorBoundary } from 'react-error-boundary'
import _ from 'lodash'

import Heading from '@/components/common/Heading'
import SearchBox from '@/components/common/SearchBox'
import { useDomainValidEmailList } from '@/module/domain/hook'
import { ErrorFallback } from '@/components/DomainManagement'
import DeferredComponent from '@/components/common/DeferredComponent'
import EmailListSkeleton from '@/components/EmailListSkeleton'

const DomainEmailManagement = lazy(async () => await import('../components/DomainEmailManagement'))

function DomainEmailConfiguration() {
  const { setFilterText, filterEmailList } = useDomainValidEmailList()

  const debounceFilterText = _.debounce((value: string) => {
    setFilterText(value)
    filterEmailList()
  }, 200)

  return (
    <Fragment>
      <Heading container={'px-6'}>허용 이메일 목록</Heading>
      <SearchBox placeholder='이메일 검색' onChange={debounceFilterText} />
      <ErrorBoundary
        FallbackComponent={ErrorFallback}
        onError={(err) => Sentry.captureException(err)}
      >
        <Suspense
          fallback={
            <DeferredComponent>
              <EmailListSkeleton />
            </DeferredComponent>
          }
        >
          <DomainEmailManagement />
        </Suspense>
      </ErrorBoundary>
    </Fragment>
  )
}

export default DomainEmailConfiguration
