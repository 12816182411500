import React, { useEffect } from 'react'
import ReactDOM from 'react-dom/client'
import { CookiesProvider } from 'react-cookie'
import {
  createBrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  RouterProvider,
  useLocation,
  useNavigationType,
} from 'react-router-dom'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'

import reportWebVitals from './reportWebVitals'
import './index.css'
import App from './App'
import ErrorPage from './pages/ErrorPage'
import Home from './pages/Home'
import AuthenticatedRoute from './pages/AuthRoute'

import { AuthContextProvider } from './module/auth/context'
import { HomeContextProvider } from './module/home/context'
import { DomainContextProvider } from './module/domain/context'
import MainFrame from './pages/MainFrame'
import DomainEmailConfiguration from './pages/DomainEmailConfiguration'
import DomainConfiguration from './pages/DomainConfiguration'
import ExceptionPathRoute from './pages/ExceptionPathRoute'
import DomainAdminEmailConfiguration from './pages/DomainAdminEmailConfiguration'

async function enableMocking() {
  if (process.env.NODE_ENV !== 'development') {
    return
  }

  const { worker } = await import('./api/mocks/browser')

  // `worker.start()` returns a Promise that resolves
  // once the Service Worker is up and ready to intercept requests.
  return await worker.start()
}

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_CLIENT_KEY_DSN,
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      ),
    }),
  ],
  tracesSampleRate: 1.0,
})

const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter)
const router = sentryCreateBrowserRouter([
  {
    path: '/',
    element: <App />,
    errorElement: (
      <ExceptionPathRoute>
        <ErrorPage />
      </ExceptionPathRoute>
    ),
    children: [
      {
        index: true,
        path: '/',
        element: (
          <AuthenticatedRoute>
            <Home />
          </AuthenticatedRoute>
        ),
      },
      {
        path: '/:productId',
        element: (
          <AuthenticatedRoute navigate>
            <MainFrame />
          </AuthenticatedRoute>
        ),
        children: [
          {
            path: '/:productId/:domain',
            element: <DomainConfiguration />,
          },
          {
            path: '/:productId/:domain/list',
            element: <DomainEmailConfiguration />,
          },
          {
            path: '/:productId/:domain/admin-list',
            element: <DomainAdminEmailConfiguration />,
          },
        ],
      },
    ],
  },
])

const queryClient = new QueryClient()
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

enableMocking().then(() => {
  root.render(
    <React.StrictMode>
      <CookiesProvider>
        <AuthContextProvider>
          <HomeContextProvider>
            <DomainContextProvider>
              <QueryClientProvider client={queryClient}>
                <RouterProvider router={router} />
              </QueryClientProvider>
            </DomainContextProvider>
          </HomeContextProvider>
        </AuthContextProvider>
      </CookiesProvider>
    </React.StrictMode>,
  )
})

reportWebVitals()
