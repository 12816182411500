import { createContext, Dispatch, ReactNode, useReducer } from 'react'
import { domainReducer } from './reducer'
import { Action, DomainState } from './types'

type DomainDispatch = Dispatch<Action>
export const DomainStateContext = createContext<DomainState | null>(null)
export const DomainDispatchContext = createContext<DomainDispatch | null>(null)

export const initialState: DomainState = {
  domain: {
    itemsAll: [],
    items: [],
    selected: null,
    count: 0,
    userCount: 0,
    filterText: '',
    filterLocale: null,
  },
  domainValidEmail: {
    itemsAll: [],
    items: [],
    selected: null,
    count: 0,
    filterText: '',
  },
  domainAdminEmail: {
    itemsAll: [],
    items: [],
    selected: null,
    count: 0,
    filterText: '',
  },
  loading: {
    save: false,
    delete: false,
  },
}

export function DomainContextProvider({ children }: { children: ReactNode }) {
  const [domain, dispatch] = useReducer(domainReducer, initialState)

  return (
    <DomainDispatchContext.Provider value={dispatch}>
      <DomainStateContext.Provider value={domain}>{children}</DomainStateContext.Provider>
    </DomainDispatchContext.Provider>
  )
}
