import {
  DomainInfo,
  DOMAIN_FILTER_VALID_EMAIL_LIST,
  DOMAIN_SET_SELECTED_DOMAIN,
  DOMAIN_SET_SELECTED_VALID_EMAIL,
  DOMAIN_SET_VALID_EMAIL_COUNT,
  DOMAIN_SET_VALID_EMAIL_FILTER_TEXT,
  DOMAIN_SET_VALID_EMAIL_LIST,
  DOMAIN_SET_FILTER_LOCALE,
  TLocale,
  DOMAIN_SET_ADMIN_EMAIL_LIST,
  DOMAIN_SET_SELECTED_ADMIN_EMAIL,
  DOMAIN_SET_ADMIN_EMAIL_FILTER_TEXT,
  DOMAIN_FILTER_ADMIN_EMAIL_LIST,
  DomainUser,
} from '@/module/domain/types'
import { useContext } from 'react'
import { DomainDispatchContext, DomainStateContext } from './context'
import {
  DomainInfoDto,
  DOMAIN_FILTER_DOMAIN_LIST,
  DOMAIN_SET_DOMAIN_COUNT,
  DOMAIN_SET_DOMAIN_LIST,
  DOMAIN_SET_DOMAIN_USER_COUNT,
  DOMAIN_SET_FILTER_TEXT,
  DOMAIN_SET_LOADING_DELETE,
  DOMAIN_SET_LOADING_SAVE,
} from './types'

function useDomainState() {
  const state = useContext(DomainStateContext)
  if (state == null) throw new Error('DomainProvider not found')
  return state
}

function useDomainDispatch() {
  const dispatch = useContext(DomainDispatchContext)
  if (dispatch == null) throw new Error('DomainProvider not found')
  return dispatch
}

export function useDomainLoading() {
  const { loading } = useDomainState()
  const dispatch = useDomainDispatch()

  const setIsSaveLoading = (loadingSave: boolean) => {
    dispatch({
      type: DOMAIN_SET_LOADING_SAVE,
      loadingSave,
    })
  }

  const setIsDeleteLoading = (loadingDelete: boolean) => {
    dispatch({
      type: DOMAIN_SET_LOADING_DELETE,
      loadingDelete,
    })
  }

  return {
    loading,
    setIsSaveLoading,
    setIsDeleteLoading,
  }
}

export function useDomainList() {
  const { domain } = useDomainState()
  const dispatch = useDomainDispatch()
  const filterText = domain.filterText
  const selectedDomain = domain.selected

  const setDomainList = (domainInfoList: DomainInfoDto[]) => {
    dispatch({
      type: DOMAIN_SET_DOMAIN_LIST,
      domainInfoList,
    })
  }

  const setSelectedDomain = (selectedDomain: DomainInfo | null) => {
    dispatch({
      type: DOMAIN_SET_SELECTED_DOMAIN,
      selectedDomain,
    })
  }

  const setFilterText = (filterText: string) => {
    dispatch({
      type: DOMAIN_SET_FILTER_TEXT,
      filterText,
    })
  }

  const setFilterLocale = (filterLocale: TLocale | null) => {
    dispatch({
      type: DOMAIN_SET_FILTER_LOCALE,
      filterLocale,
    })
  }

  const filterDomainList = () => {
    dispatch({
      type: DOMAIN_FILTER_DOMAIN_LIST,
    })
  }

  const setDomainCount = (domainCount: number) => {
    dispatch({
      type: DOMAIN_SET_DOMAIN_COUNT,
      domainCount,
    })
  }

  const setDomainUserCount = (domainUserCount: number) => {
    dispatch({
      type: DOMAIN_SET_DOMAIN_USER_COUNT,
      domainUserCount,
    })
  }

  return {
    domain,
    filterText,
    selectedDomain,
    setDomainList,
    setSelectedDomain,
    setFilterText,
    setFilterLocale,
    filterDomainList,
    setDomainCount,
    setDomainUserCount,
  }
}

export function useDomainValidEmailList() {
  const { domainValidEmail } = useDomainState()
  const dispatch = useDomainDispatch()
  const filterText = domainValidEmail.filterText

  const setEmailList = (validEmailList: string[]) => {
    dispatch({
      type: DOMAIN_SET_VALID_EMAIL_LIST,
      validEmailList,
    })
  }

  const setSelectedEmail = (selectedValidEmail: string) => {
    dispatch({
      type: DOMAIN_SET_SELECTED_VALID_EMAIL,
      selectedValidEmail,
    })
  }

  const setFilterText = (filterText: string) => {
    dispatch({
      type: DOMAIN_SET_VALID_EMAIL_FILTER_TEXT,
      filterText,
    })
  }

  const filterEmailList = () => {
    dispatch({
      type: DOMAIN_FILTER_VALID_EMAIL_LIST,
    })
  }

  const setEmailCount = (validEmailCount: number) => {
    dispatch({
      type: DOMAIN_SET_VALID_EMAIL_COUNT,
      validEmailCount,
    })
  }

  return {
    domainValidEmail,
    filterText,
    setEmailList,
    setSelectedEmail,
    setFilterText,
    filterEmailList,
    setEmailCount,
  }
}

export function useDomainUserList() {
  const { domainAdminEmail } = useDomainState()
  const dispatch = useDomainDispatch()
  const filterText = domainAdminEmail.filterText

  const setAdminEmailList = (payload: DomainUser[]) => {
    dispatch({
      type: DOMAIN_SET_ADMIN_EMAIL_LIST,
      payload,
    })
  }

  const setSelectedAdminEmail = (payload: DomainUser) => {
    dispatch({
      type: DOMAIN_SET_SELECTED_ADMIN_EMAIL,
      payload,
    })
  }

  const setFilterText = (payload: string) => {
    dispatch({
      type: DOMAIN_SET_ADMIN_EMAIL_FILTER_TEXT,
      payload,
    })
  }

  const filterAdminEmailList = () => {
    dispatch({
      type: DOMAIN_FILTER_ADMIN_EMAIL_LIST,
    })
  }

  return {
    domainAdminEmail,
    filterText,
    setAdminEmailList,
    setSelectedAdminEmail,
    setFilterText,
    filterAdminEmailList,
  }
}
