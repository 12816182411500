import React from 'react'

function DomainListSkeleton() {
  const bgAnimate = 'bg-gray-100 animate-pulse'

  return (
    <div>
      <div className='flex items-center justify-between w-full'>
        <div className='flex mx-6 space-x-2'>
          <div className={`rounded-xl text-xs w-[55px] h-[18px] ${bgAnimate}`}></div>
          <div className={`rounded-xl text-xs w-[65px] h-[18px] ${bgAnimate}`}></div>
        </div>
        <div className='px-3 pb-1'>
          <button className='items-center space-x-1 btn btn-ghost btn-sm'>
            <i className='text-gray-100 fa-solid fa-circle-plus animate-pulse'></i>
            <span className='text-sm text-gray-100 animate-pulse'>도메인 추가</span>
          </button>
        </div>
      </div>
      <ul className='px-4 text-sm menu text-base-content grow'>
        {Array.from({ length: 3 }, (_, i) => i + 1).map((i) => (
          <div key={i} className='flex items-center justify-between w-full rounded-lg'>
            <div className='p-4 space-y-1'>
              <div className={`h-[15px] w-12 ${bgAnimate}`}></div>
              <div className={`h-[15px] w-40 ${bgAnimate}`}></div>
            </div>
            <div className='flex flex-col p-4 rounded-lg'>
              <div className='flex justify-end '>
                <div className={`h-[15px] w-12 ${bgAnimate}`}></div>
              </div>
              <div className={`h-[5px] w-20 ${bgAnimate}`}></div>
            </div>
          </div>
        ))}
      </ul>
    </div>
  )
}

export default DomainListSkeleton
