import React from 'react'

function EmailListSkeleton() {
  const bgAnimate = 'bg-gray-100 animate-pulse'

  return (
    <div className='px-4 grow'>
      <div className='flex items-center justify-between pb-2 pl-2'>
        <div>
          <div className='font-normal btn btn-sm btn-ghost'>
            <div className={`rounded-xl text-xs w-[55px] h-[18px] ${bgAnimate}`}></div>
            <span className='text-xs'> 선택</span>
          </div>
        </div>
        <div>
          <div className='btn btn-sm btn-ghost'>
            <i className='mr-1 text-gray-100 fa-solid fa-minus animate-pulse'></i>
            <span className='text-sm text-gray-100 animate-pulse'>삭제</span>
          </div>
          <div className='items-center ml-1 btn btn-ghost btn-sm'>
            <i className='mr-1 text-gray-100 fa-solid fa-plus animate-pulse'></i>
            <span className='text-sm text-gray-100 animate-pulse'>추가</span>
          </div>
        </div>
      </div>

      <div className='mt-0 '>
        <ul className='text-sm menu text-base-content'>
          {Array.from({ length: 3 }, (_, i) => i + 1).map((i) => (
            <li key={i}>
              <label>
                <input type='checkbox' className='checkbox checkbox-ghost checkbox-xs'></input>
                <div className={`h-[15px] w-12 ${bgAnimate}`}></div>
              </label>
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}

export default EmailListSkeleton
