import React, { Fragment } from 'react'
import { Navigate } from 'react-router-dom'

import useRegExpMatch from '@/components/common/hooks/useRegExpMatch'

function ExceptionPathRoute({ children }: { children: React.ReactNode }) {
  const matches = useRegExpMatch()

  if (matches) {
    return <Navigate to={matches.path} />
  }

  return <Fragment>{children}</Fragment>
}

export default ExceptionPathRoute
