import React from 'react'
import { menuList } from '@/module/home/constant'
import { Link } from 'react-router-dom'
import { useSelected } from '@/module/home/hook'
import { useAuth } from '@/module/auth/hook'

function SideMenu() {
  const { user } = useAuth()
  const { productType } = useSelected()

  return (
    <header className='navbar hidden lg:flex lg:flex-col lg:w-[300px] h-screen bg-base-100'>
      <div className='flex-none w-full justify-left'>
        <div className='p-3 font-bold select-none'>
          <span className='text-xl text-primary'>Flexcil</span>
          <p>
            <span className='text-2xl'>Domain Manager</span>
          </p>
        </div>
      </div>
      <div className='items-start flex-1 w-full mt-2'>
        <ul className='w-full p-2 menu rounded-box'>
          <li className='menu-title'>
            <span>상품 목록</span>
          </li>
          {menuList.map((menu) => (
            <li key={menu.id}>
              <Link to={`/${menu.id}`} className={`${menu.id === productType ? 'active' : ''}`}>
                {menu.name}
              </Link>
            </li>
          ))}
        </ul>
      </div>
      <div className='flex items-center w-full'>
        <div className='dropdown dropdown-top'>
          <label tabIndex={0} className='btn btn-ghost btn-circle avatar'>
            <div className='w-8 rounded-full'>
              <img src={user.picture} alt={`${user.name}'s profile`} />
            </div>
          </label>
          <ul
            tabIndex={0}
            className='p-2 mt-3 shadow menu dropdown-content bg-base-100 rounded-box w-52'
          >
            <li>
              <a href={process.env.REACT_APP_LOGOUT_URL}>Logout</a>
            </li>
          </ul>
        </div>
        <div className='text-sm'>{user.name}</div>
      </div>
    </header>
  )
}

export default SideMenu
