import { useContext } from 'react'
import { ProductType } from '@/types'
import { HomeDispatchContext, HomeStateContext, initialDomain } from './context'
import {
  HOME_SET_ADMIN_LIST,
  HOME_SET_DEBUG,
  HOME_SET_OPEN_PRODUCT_DROPDOWN,
  HOME_SET_OPEN_PROFILE_DROPDOWN,
  HOME_SET_OPEN_SIDEMENU,
  HOME_SET_SELECTED_DOMAIN,
  HOME_SET_SELECTED_PRODUCT_TYPE,
  HOME_SET_VALID_EMAIL_TYPE,
} from './types'
import { DomainInfo } from '../domain/types'

function useHomeState() {
  const state = useContext(HomeStateContext)
  if (state == null) throw new Error('HomeProvider not found')
  return state
}

function useHomeDispatch() {
  const dispatch = useContext(HomeDispatchContext)
  if (dispatch == null) throw new Error('HomeProvider not found')
  return dispatch
}

export function useDebug() {
  const { debug } = useHomeState()
  const dispatch = useHomeDispatch()

  const setDebug = (debug: boolean) => {
    dispatch({
      type: HOME_SET_DEBUG,
      debug,
    })
  }

  return { debug, setDebug }
}

export function useSelected() {
  const { selected } = useHomeState()
  const dispatch = useHomeDispatch()

  const setSelectedDomain = (domain?: DomainInfo) => {
    if (domain == null) {
      dispatch({
        type: HOME_SET_SELECTED_DOMAIN,
        domain: initialDomain,
      })

      return
    }

    dispatch({
      type: HOME_SET_SELECTED_DOMAIN,
      domain,
    })
  }

  const setSelectedProductType = (productType: ProductType) => {
    dispatch({
      type: HOME_SET_SELECTED_PRODUCT_TYPE,
      productType,
    })
  }

  const setIsValidEmailType = (validEmailType: boolean) => {
    dispatch({
      type: HOME_SET_VALID_EMAIL_TYPE,
      validEmailType,
    })
  }

  const setIsAdminList = (adminList: boolean) => {
    dispatch({
      type: HOME_SET_ADMIN_LIST,
      adminList,
    })
  }

  return {
    productType: selected.productType,
    domain: selected.domain,
    validEmailType: selected.validEmailType,
    adminList: selected.adminList,
    setSelectedProductType,
    setSelectedDomain,
    setIsValidEmailType,
    setIsAdminList,
  }
}

export function useOpened() {
  const { opened } = useHomeState()
  const dispatch = useHomeDispatch()

  const setOpenProductDropdown = (openProductDropdown: boolean) => {
    dispatch({
      type: HOME_SET_OPEN_PRODUCT_DROPDOWN,
      openProductDropdown,
    })
  }

  const setOpenProfileDropwdown = (openProfileDropdown: boolean) => {
    dispatch({
      type: HOME_SET_OPEN_PROFILE_DROPDOWN,
      openProfileDropdown,
    })
  }

  const setOpenSidemenu = (openSidemenu: boolean) => {
    dispatch({
      type: HOME_SET_OPEN_SIDEMENU,
      openSidemenu,
    })
  }

  const {
    productDropdown: openProductDropwdown,
    profileDropdown: openProfileDropdown,
    sidemenu: openSidemenu,
  } = opened

  return {
    openProductDropwdown,
    openProfileDropdown,
    openSidemenu,
    setOpenSidemenu,
    setOpenProductDropdown,
    setOpenProfileDropwdown,
  }
}
