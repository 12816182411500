import { ProductType } from '@/types'
import { DomainInfo } from '../domain/types'

export type HomeState = {
  debug: boolean
  opened: {
    productDropdown: boolean
    profileDropdown: boolean
    sidemenu: boolean
  }
  selected: {
    productType: ProductType
    domain: DomainInfo
    validEmailType: boolean
    adminList: boolean
  }
}

export const HOME_SET_DEBUG = 'HOME/SET_DEBUG' as const
export const HOME_SET_SELECTED_DOMAIN = 'HOME/SET_SELECTED_DOMAIM' as const
export const HOME_SET_SELECTED_PRODUCT_TYPE = 'HOME/SET_SELECTED_PRODUCT_TYPE' as const
export const HOME_SET_OPEN_PRODUCT_DROPDOWN = 'HOME/SET_OPEN_PRODUCT_NAV' as const
export const HOME_SET_OPEN_PROFILE_DROPDOWN = 'HOME/SET_OPEN_PROFILE_DROPDOWN' as const
export const HOME_SET_OPEN_SIDEMENU = 'HOME/SET_OPEN_SIDEMENU' as const
export const HOME_SET_VALID_EMAIL_TYPE = 'HOME/SET_VALID_EMAIL_TYPE' as const
export const HOME_SET_ADMIN_LIST = 'HOME/SET_ADMIN_LIST' as const

export type Action =
  | { type: typeof HOME_SET_DEBUG; debug: boolean }
  | { type: typeof HOME_SET_SELECTED_DOMAIN; domain: DomainInfo }
  | {
      type: typeof HOME_SET_SELECTED_PRODUCT_TYPE
      productType: ProductType
    }
  | {
      type: typeof HOME_SET_OPEN_PRODUCT_DROPDOWN
      openProductDropdown: boolean
    }
  | {
      type: typeof HOME_SET_OPEN_PROFILE_DROPDOWN
      openProfileDropdown: boolean
    }
  | {
      type: typeof HOME_SET_OPEN_SIDEMENU
      openSidemenu: boolean
    }
  | {
      type: typeof HOME_SET_VALID_EMAIL_TYPE
      validEmailType: boolean
    }
  | {
      type: typeof HOME_SET_ADMIN_LIST
      adminList: boolean
    }
