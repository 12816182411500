import { initialUser } from './context'
import { AuthState, AUTH_SET_USER, Action } from './types'

export function authReducer(state: AuthState, action: Action) {
  switch (action.type) {
    case AUTH_SET_USER:
      return { ...state, isAuth: action.user !== initialUser, user: action.user }
    default:
      throw Error('Not Exist Action In authReducer')
  }
}
